<template>
  <router-link :to="toRoute" class="parts-menu-item">
    <img class="image" :src="part.hover_icon" />
    {{ part.display_name }}
  </router-link>
</template>

<script>
export default {
  props: {
    site: Object,
    part: Object,
    partKey: String,
  },
  computed: {
    toRoute() {
      const partkey = this.isDefaultPart ? '' : this.partKey;
      const params = {username: this.site.username, sitename: this.site.name, partkey: partkey};
      return {name: this.routeName, params: params};
    },
    routeName() {
      if (this.$route.meta && this.$route.meta.isJLTReport) {
        return this.isDefaultPart ? 'jlt-report-site-name' : 'jlt-report-site-name-part';
      }
      return this.isDefaultPart ? 'fws-site-name' : 'fws-site-name-part';
    },
    isDefaultPart() {
      return this.partKey === 'default';
    },
  },
  name: 'PartsMenuItem',
};
</script>

<style lang="scss" scoped>
.parts-menu-item {
  position: relative;
  padding: 16px 15px 16px var(--report-closed-menu-width);
  text-decoration: none;
  color: #999;
  font-weight: bold;
  transition: all 0.5s ease;
  background-color: #282828;
  width: 100%;
  white-space: nowrap;

  .image {
    position: absolute;
    top: 14px;
    left: 13px;
    width: 35px;
    transition: all 0.5s ease;
    opacity: 0.5;
    filter: grayscale(100%);
  }

  &:hover {
    background-color: #333;
    color: #ffd46c;

    .image {
      opacity: 1;
      filter: grayscale(0%);
    }
  }
}
</style>
