<template>
  <div class="field-report-template-menu" :class="classes">
    <div
      class="menu"
      :class="{'is-hidden': fullyHideMenu}"
      @click="mobileOpen"
      @mouseenter="desktopOpen"
      @mouseleave="desktopClose"
    >
      <parts-menu></parts-menu>
      <sites-menu v-show="isOpened" :is-mobile="isMobile"></sites-menu>
      <div v-show="!isOpened" class="sites-menu-placeholder">
        <img class="image" :src="$getAsset('/assets/jlt_img/menu/1_gray.png')" />
      </div>
    </div>
    <div v-if="isMobile" class="hidden-overlay" @click="close"></div>
  </div>
</template>

<script>
import SitesMenu from '@/components/modules/familyWebsite/templates/SitesMenu';
import PartsMenu from '@/components/modules/familyWebsite/templates/field_report/PartsMenu';

export default {
  components: {PartsMenu, SitesMenu},
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
    classes() {
      return {'is-opened': this.isOpened};
    },
    isMobile() {
      return this.$store.getters.windowWidthState < this.$breakpoints.desktop;
    },
    fullyHideMenu() {
      return !this.isOpened && this.$store.getters.windowWidthState <= this.$breakpoints.phablet;
    },
  },
  methods: {
    open() {
      this.isOpened = true;
    },
    close() {
      this.isOpened = false;
    },
    mobileOpen() {
      if (this.isMobile) {
        this.open();
      }
    },
    desktopOpen() {
      if (!this.isMobile) {
        this.open();
      }
    },
    desktopClose() {
      if (!this.isMobile) {
        this.close();
      }
    },
  },
  name: 'FieldReportTemplateMenu',
};
</script>

<style lang="scss" scoped>
/* .field-report-template-menu has delay so .menu able to play close animation */
/* .field-report-template-menu.is-open transition happens without delay, so .menu able to play open animation */
$menu-animation-duration: 0.5s;
$menu-opened-width-desktop: 340px;

.field-report-template-menu {
  position: fixed;
  z-index: 5;
  height: 100%;
  display: flex;
  width: var(--report-closed-menu-width);
  transition: all 0s;
  transition-delay: $menu-animation-duration;

  .menu {
    position: relative;
    width: var(--report-closed-menu-width);
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all $menu-animation-duration ease;

    .parts-menu {
      padding-top: var(--report-menu-sites-closed-height);
    }

    .sites-menu,
    .parts-menu {
      width: 100%;

      @media only screen and (min-width: $breakpoint-desktop) {
        width: $menu-opened-width-desktop;
      }
    }
  }

  .menu.is-hidden {
    height: var(--report-menu-sites-closed-height);

    .parts-menu {
      padding-top: 0;
      width: 0;
      transition: all 0s;
      transition-delay: $menu-animation-duration;
      overflow: hidden;
    }
  }

  &.is-opened {
    transition-delay: 0s;
    width: 100%;

    .menu {
      width: 80%;

      @media only screen and (min-width: $breakpoint-desktop) {
        width: $menu-opened-width-desktop;
      }
    }
  }

  .sites-menu-placeholder {
    background-color: #333;
    cursor: pointer;
    height: 80px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .image {
      position: absolute;
      left: 13px;
      top: 23px;
      height: 35px;
    }
  }

  .parts-menu {
    background-color: #212121;
    flex-grow: 1;
  }

  .hidden-overlay {
    flex-grow: 1;
  }
}
</style>
