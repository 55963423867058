<template>
  <div class="parts-menu">
    <parts-menu-item
      v-for="part in parts"
      :part="part"
      :part-key="part.key"
      :site="familyWebsiteDetailsState"
      :key="part.key"
    ></parts-menu-item>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import PartsMenuItem from '@/components/modules/familyWebsite/templates/field_report/PartsMenuItem';

export default {
  computed: {
    ...mapGetters(['familyWebsiteDetailsState']),
    parts() {
      return Object.values(this.familyWebsiteDetailsState.template_parts).sort(
        (a, b) => a.tpl_priority - b.tpl_priority
      );
    },
  },
  components: {PartsMenuItem},
};
</script>

<style lang="scss" scoped>
.parts-menu {
  display: flex;
  flex-direction: column;

  .parts-menu-item {
    display: flex;
    flex-grow: 0;
  }
}
</style>
